import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);
export const store = new Vuex.Store({
    modules: {

    },
    state: {
        itemsCourse: [],
        itemsEvents: [],
        itemsVenues: [],
        itemsParticipants: [],
        venueDetails: null,
        course_id: null,
        course_id_request: null,
        event_id: null,
        venue_id: null,
        event_id_request: null,
        participant_id: null,
        enrolment_id: null,
        itemParticipantsDetails: {},
        event: {},
        participant: {},
        enrollment: {},
        emailKey: false,
        participantDetail: {},
        error: ""
    },
    getters: {
        getCourseDetails: state => {
            const details = state.itemsCourse.filter(
                (item) => item.id == state.course_id
            );
            return details[0];
        },
        getEventDetails: (state) => {
            const details = state.itemsEvents.filter(
                (item) => item.id == state.event_id
            );
            return details[0];
        },
        getVenueDetails: state => {
            if (state.venueDetails) {
                return state.venueDetails[0];
            }
            return null;
        },
        emailKey: state => {
            return state.emailKey;
        },
        participantDetail: state => {
            console.info("Getters participant detail", state.participantDetail);
            return state.participantDetail;
        },
        error: state => {
            return state.error;
          },
    },
    mutations: {
        storeVenueId(state, payload) {
            state.venue_id = payload;
            const details = state.itemsVenues.results.filter(
                (item) => item.id == state.venue_id
            );
            state.venueDetails = details;
        },
        storeEnrolmentId(state, payload) {
            state.enrolment_id = payload;
        },
        storeAllParticipants(state, payload) {
            console.log('storeAllParticipants', payload)
            state.itemsParticipants = payload
        },
        storeParticipantId(state, payload) {
            state.participant_id = payload
        },
        storeItemEnrollment(state, payload) {
            state.enrollment = payload;

        },
        storeItemEvent(state, payload) {
            state.event = payload;

        },
        storeItemParticipant(state, payload) {
            state.participant = payload;
        },
        storeItemParticipantsDetails(state, payload) {
            state.itemParticipantsDetails = payload;
        },
        storeCourseId(state, payload) {
            state.course_id = payload;
        },
        storeCourseIdRequest(state, payload) {
            state.course_id_request = payload;
        },
        storeEventId(state, payload) {
            state.event_id = payload;
        },
        storeEventIdRequest(state, payload) {
            state.event_id_request = payload;
        },
        storeAllCourse(state, payload) {
            state.itemsCourse = payload;
            // console.log("api/course state.itemsCourse", state.itemsCourse)
        },
        storeAllEvents(state, payload) {
            state.itemsEvents = payload;
            // console.log("api/course state.itemsCourse", state.itemsCourse)
        },
        storeAllVenues(state, payload) {
            state.itemsVenues = payload;


            // console.log("api/course state.itemsCourse", state.itemsCourse)
        },
        setEmailKey(state, payload) {
            state.emailKey = payload;
        },
        setParticipantDetail(state, payload) {
            state.participantDetail = payload.participantDetail;
        },
        setError(state, payload) {
            state.error = payload.error;
          },
    },
    actions: {
        /* eslint-disable */
        async updateParticipantEvent({ commit }, payload) {
            // { commit, dispatch }
            console.log('updateParticipantEvent', payload)


            //add three variable to form
            var formdata = {
                event: payload.event_id,
                participant: payload.participant,
                extras: [],
                meal_option: null,
            }

            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .put("/api/enrolment/" + payload.enrolment_id + '/', formdata)
                .then((res) => {
                    console.log('updateParticipantEvent', res)
                    window.location.replace(
                        process.env.VUE_APP_BASE_URL_APP_ADMIN +
                        "#/participant/edit/" + payload.participant
                    );
                    // commit('storeItemParticipant', res.data)
                    // dispatch('createEnrollment', res.data)
                });
        },
        async updateParticipant({ commit }, payload) {
            // { commit, dispatch }
            console.log('updateParticipant', payload)


            //add three variable to form
            var formdata = {
                id: payload.id,
                title: payload.title,
                first_name: payload.firstName,
                last_name: payload.lastName,
                company_name: payload.companyName,
                phone_number: payload.phoneNumber,
                address_line_1: payload.postalAddress,
                address_line_2: payload.addressLine2,
                suburb: payload.suburb,
                state: payload.state,
                post_code: payload.postCode,
                country: payload.country,
            }

            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .put("/api/participant/" + payload.id + '/', formdata)
                .then((res) => {
                    console.log('updateParticipant', res)
                });
        },
        async createParticipants({ commit, dispatch }, payload) {
            console.log('createParticipants', payload)
            var formdata = new FormData();
            //add three variable to form
            formdata.append("title", payload.title);
            formdata.append("first_name", payload.firstName);
            formdata.append("last_name", payload.lastName);
            formdata.append("company_name", payload.companyName);
            formdata.append("phone_number", payload.phoneNumber);
            formdata.append("email_address", payload.email);
            formdata.append("address_line_1", payload.postalAddress);
            formdata.append("address_line_2", payload.addressLine2);
            formdata.append("suburb", payload.suburb);
            formdata.append("post_code", payload.postCode);
            formdata.append("state", payload.state);
            formdata.append("country", payload.country);

            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .post("/api/participant/", formdata)
                .then((res) => {
                    commit('storeItemParticipant', res.data)

                    const result = {...res.data, eventType: payload.eventType,  getEventDetails: payload.getEventDetails }
                    console.info("Result from Create Participant: ", result)
                    dispatch('createEnrollment', result)
                }).catch(error => {
                    alert(error.response.data.email_address)
                    console.log('error in creating participant', error.response.data)
                });
        },
        async createEnrollment({ commit, dispatch, state, getters }, payload) {
            console.log('createEnrollment', payload)
            var formdata = {
                event: state.event_id,
                paid: false,
                total_cost: getters.getEventDetails.cost,
                participant: payload.id,
                extras: [],
                meal_option: null,
                _new: true,
            }
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .post("/api/enrolment/", formdata)
                .then(async (res) => {
                    commit('storeItemEnrollment', res.data)
                    commit("setEmailKey", { emailKey: false })
                    console.warn("Enrolment res: ", res)
                    const result = {...payload.getEventDetails, eventType: payload.eventType }

                    dispatch("updateEvent", result);
                    return true;

                }).catch(error => {
                    alert('Something went wrong!')
                    console.log('error in creating enrolment', error)
                });
        },
        getAllItemParticipantsDetails({ commit }, payload) {
            commit('storeItemParticipantsDetails', payload);
        },
        async getAllCourse() {
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .get("/api/course/?filter=active")
                .then((res) => {
                    this.commit('storeAllCourse', res.data)

                });
        },
        async getAllEvents() {
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .get("/api/event/?filter=active")
                .then((res) => {
                    this.commit('storeAllEvents', res.data)

                });
        },
        async updateEvent({ commit, dispatch, state, getters }, payload) {
            console.log('updateEvent', payload)
            let plus_confirmed_enrolments = 0;
            let new_enrolments = 0;



            if (payload.eventType == 'INCREMENT_CONFIRMED_ENROLLMENTS') {
                plus_confirmed_enrolments = payload.confirmed_enrolments + 1;
            }


            if (payload.eventType == 'INCREMENT_ENROLLMENTS') {
                new_enrolments = payload.enrolments + 1;
            }

            var formdata = {
                ...payload,
                id : payload.id,
                confirmed_enrolments : plus_confirmed_enrolments > 0?plus_confirmed_enrolments: payload.confirmed_enrolments,
                enrolments : new_enrolments > 0 ? new_enrolments : payload.enrolments,
            }
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .put("/api/event/"+payload.id+"/", formdata)
                .then((res) => {
                    console.log('res event', res.data)
                     commit('storeItemEvent', res.data)
                     dispatch("getAllEvents");
                    return true;

                }).catch(error => {
                    alert('Something went wrong!')
                    console.log('error in updating events', error)
                });
        },

        async getAllVenues() {
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .get("/api/venue/?_code=true&limit=2000&offset=0&q=")
                .then((res) => {
                    this.commit('storeAllVenues', res.data)

                });
        },
        async getAllParticipants() {
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                .get("/api/participant/?_code=true&limit=2000&offset=0&q=")
                .then((res) => {
                    // console.log('getAllParticipants', res)
                    this.commit('storeAllParticipants', res.data.results)

                });
        },
        // get enrolment info
        async getEnrolment({ commit, dispatch }, payload) {
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                // http://localhost:8000/api/enrolment/13/ local setup
                .get("/api/enrolment/" + payload + "/")
                .then((res) => {
                    console.log('getEnrolment', res)
                    commit('storeItemEnrollment', res.data)
                    dispatch('getEvent', res.data.event)
                    return true;


                }).catch(error => {
                    return false;
                });
        },
        // get event info
        async getEvent({ commit, dispatch, state }, payload) {
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                // http://localhost:8000/api/enrolment/13/ local setup
                .get("/api/event/" + payload + "/")
                .then((res) => {
                    console.log('getEvent', res)
                    console.log('state.enrollment', state.enrollment)
                    commit('storeItemEvent', res.data)
                    commit('storeCourseId', res.data.course)
                    commit('storeEventId', state.enrollment.event)
                    commit('storeParticipantId', state.enrollment.participant)
                    commit('storeEnrolmentId', state.enrollment.id)
                    commit('storeVenueId', res.data.venue)
                    dispatch('getParticipant', state.enrollment.participant)
                    // dispatch('setEventId', state.enrollment.course_class)
                    // dispatch('setParticipantId', state.enrollment.participant)
                    // dispatch('setEnrolmentId', state.enrollment.id)
                    // dispatch('setVenueId', res.data.venue)

                    return true;


                }).catch(error => {
                    return false;
                });
        },
        // get participant info
        async getParticipant({ commit, state }, payload) {
            Vue.axios
                .create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    headers: {
                        "Content-Type": "*/*",
                        Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
                    },
                })
                // http://localhost:8000/api/enrolment/13/ local setup
                .get("/api/participant/" + payload + "/")
                .then((res) => {
                    console.log('getParticipant', res)
                    commit('storeItemParticipantsDetails', res.data);
                    // dispatch('setCourseId', res.data.course)
                    // dispatch('setEventId', state.enrollment.course_class)
                    // dispatch('setParticipantId', state.enrollment.participant)
                    // dispatch('setEnrolmentId', state.enrollment.id)
                    // dispatch('setVenueId', res.data.venue)

                    return true;


                }).catch(error => {
                    return false;
                });
        },
        // get course info
        setCourseId({ commit }, payload) {
            commit('storeCourseId', payload)
        },
        setCourseIdRequest({ commit }, payload) {
            commit('storeCourseIdRequest', payload)
        },
        setEventId({ commit }, payload) {
            commit('storeEventId', payload)
        },
        setEventIdRequest({ commit }, payload) {
            commit('storeEventIdRequest', payload)
        },
        setParticipantId({ commit }, payload) {
            commit('storeParticipantId', payload)
        },
        setEnrolmentId({ commit }, payload) {
            commit('storeEnrolmentId', payload)
        },
        setVenueId({ commit }, payload) {
            commit('storeVenueId', payload)
        },
        // error handling
        error({ dispatch, commit }, errorMessage) {
            console.log(errorMessage)
            commit('setError', errorMessage) 
          }
    }
})
