// import ServiceTest from './pages/ServiceTest.vue'
import SelectCourse from "./pages/SelectCourse.vue";
import EnrollCourse from "./pages/EnrollCourse.vue";
import PaymentDetails from "./pages/PaymentDetails.vue";
import RedirectPageChangeEvents from "./pages/RedirectPageChangeEvents.vue";

export default [
  { path: "/", component: SelectCourse },
  { path: "/selected-course/:id", component: SelectCourse },
  {
    path: "/redirect-change-events/:id/:event_id/:participant_id/:enrolment_id",
    component: RedirectPageChangeEvents,
  },
  { path: "/enroll-course/:id/:event_id", component: EnrollCourse },
  { path: "/dopayment/:uuid", component: PaymentDetails },
];
