<template>
  <div>
    <h2 v-if="courseDetails">{{ courseDetails.name }}</h2>
    <h3 v-if="courseDetails">{{ courseDetails.subline }}</h3>
    <!-- {{courseDetails}} -->
    <div class="allcoursedetails">
      <div v-if="courseDetails" v-html="courseDetails.details"></div>
      <p></p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["getCourseDetails"],
  data() {
    return {};
  },
  computed: {
    courseDetails() {
      return this.getCourseDetails[0];
    },
  },
};
</script>
<style scoped>
body {
  background: #dddcdc;
}
.btn-span {
  padding-bottom: 5px;
}
.btn-span a {
  background: rgb(255, 175, 75);
}
body {
  background-color: #f2f2f2;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 5px;
  color: #535353;
}
.course a {
  color: #f60;
}
.alert-message {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 18px;
  color: #404040;
  background-color: #eedc94;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  border-width: 1px;
  border-style: solid;
}
.row {
  display: flex;
  justify-content: center;
}
.col1 {
  background-color: #f1f1f3;
  border-right: 1px dotted #f60;
  width: 350px;
}
.col2 {
  width: 460px;
  margin-left: 30px;
}

.main {
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 850px;
  background-color: #fff;
  box-shadow: 10px 10px 10px #ccc;
}
.header-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-logo a {
  color: #0088cc;
  text-decoration: none;
  cursor: pointer;
}
.main-content {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-content h1 {
  font-size: 30px;
  line-height: 36px;
  padding-top: 10px;
  padding-left: 5px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #f60;
}
h1,
h2,
h3 {
  font-weight: bold;
}
</style>