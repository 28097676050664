<template>
  <div>
    <!-- {{courseDetails.length}} -->
    <div v-for="(courseDetail, index) in courseDetails" :key="index">
      <!-- {{ courseDetail }} -->
      <table
        border="1"
        cellspacing="0"
        cellpadding="0"
        style="border-collapse: collapse; border: none"
      >
        <tbody>
          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border-top: solid windowtext 1pt;
                border-left: solid windowtext 1pt;
                border-bottom: none;
                border-right: none;
                background: #d9d9d9;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >Location:
                  </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border-top: solid windowtext 1pt;
                border-left: none;
                border-bottom: none;
                border-right: solid windowtext 1pt;
                background: #d9d9d9;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >{{ courseDetail.venue__city }}
                  </span>
                </font>
              </p>
            </td>
          </tr>
          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >Trainer:
                  </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d">
                    {{ courseDetail.trainer__title }}
                    {{ courseDetail.trainer__first_name }}
                    {{ courseDetail.trainer__last_name }}
                  </span>
                </font>
              </p>
            </td>
          </tr>
          <tr height="55" style="min-height: 41.6pt">
            <td
              width="94"
              height="55"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 41.6pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d">Venue: </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="55"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 41.6pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d">
                    {{ courseDetail.venue__name }},
                    <VenueStreetAddress
                      :charAt="courseDetail.venue__street_address.charAt(0)"
                      :street_address="courseDetail.venue__street_address"
                    />
                  </span>
                </font>
              </p>
            </td>
          </tr>
          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d">Dates: </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d">
                    <NumberDaysOfEvents :dates="courseDetail.dates" />
                  </span>
                </font>
              </p>
            </td>
          </tr>

          <tr
            height="18"
            v-for="(date, index) in courseDetail.dates"
            :key="index"
            style="min-height: 13.85pt"
          >
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"> </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d">
                    {{ convertDate(date.start_date, date.end_date) }} -
                    {{ convertTime(date.end_date, date.end_date) }} {
                    {{ date.description }} }
                  </span>
                </font>
              </p>
            </td>
          </tr>

          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >Payment Information:
                  </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <div
                v-if="courseDetail.payment_information"
                v-html="courseDetail.payment_information"
              ></div>
            </td>
          </tr>
          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >Max Places:
                  </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >{{ courseDetail.max_participants }}
                  </span>
                </font>
              </p>
            </td>
          </tr>
          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >Places Left:
                  </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <TotalEnrolled
                :max_participants="courseDetail.max_participants"
                :confirmed_enrolments="courseDetail.confirmed_enrolment_count"
                :enrolments="courseDetail.enrolments"
              />
            </td>
          </tr>
          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border: none;
                border-left: solid windowtext 1pt;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >Provisional Enrolments:
                  </span>
                </font>
              </p>
            </td>
            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border: none;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d"
                    >{{ courseDetail.enrolment_count }}
                  </span>
                </font>
              </p>
            </td>
          </tr>
          <tr height="18" style="min-height: 13.85pt">
            <td
              width="94"
              height="18"
              valign="top"
              style="
                width: 70.5pt;
                border-top: none;
                border-left: solid windowtext 1pt;
                border-bottom: solid windowtext 1pt;
                border-right: none;
                background: #f2f2f2;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri"
                  ><span style="font-size: 11pt; color: #1f497d">Fee: </span>
                </font>
              </p>
            </td>

            <td
              width="472"
              height="18"
              valign="top"
              style="
                width: 354.35pt;
                border-top: none;
                border-left: none;
                border-bottom: solid windowtext 1pt;
                border-right: solid windowtext 1pt;
                padding: 0cm 5.4pt 0cm 5.4pt;
                min-height: 13.85pt;
              "
            >
              <p class="">
                <font size="2" color="#1f497d" face="Calibri">
                  <span style="font-size: 11pt; color: #1f497d">
                    ${{ courseDetail.cost }} plus GST
                    <span
                      class="btn-span"
                      style="float: right; padding-bottom: 5px"
                    >
                      <EnrollNow
                        :event_id_request="event_id_request"
                        :course="courseDetail.course"
                        :id="courseDetail.id"
                        :max_participants="courseDetail.max_participants"
                        :confirmed_enrolments="
                          courseDetail.confirmed_enrolment_count
                        "
                        :enrolments="
                          courseDetail.enrolments
                        "
                        @enrollCourse="enrollCourse"
                      />

                      <b-button
                        disabled
                        v-if="event_id_request == courseDetail.id"
                        variant="primary"
                      >
                        Already Enrolled
                      </b-button>

                      <b-button
                        @click="changeParticipantEvent(courseDetail.id)"
                        v-if="
                          event_id_request != courseDetail.id &&
                          event_id_request
                        "
                        variant="primary"
                      >
                        Move {{ getParticipantFullname }}
                      </b-button>
                    </span>
                  </span>
                </font>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import VenueStreetAddress from "./VenueStreetAddress.vue";
import { mapState } from "vuex";
import NumberDaysOfEvents from "./NumberDaysOfEvents.vue";
import TotalEnrolled from "./TotalEnrolled.vue";
import EnrollNow from "./Button/EnrollNow.vue";

export default {
  props: ["getCourseDetails", "events", "venues"],
  data() {
    return {};
  },
  methods: {
    changeParticipantEvent(event_id) {
      let confirmNow = confirm("Move " + this.getParticipantFullname + "?");
      if (confirmNow) {
        let data = {
          enrolment_id: this.enrolment_id,
          event_id: event_id,
          participant: this.participant_id,
        };
        this.$store.dispatch("updateParticipantEvent", data);
      }
    },
    getDaysOfWeek(date) {
      const dateNow = moment(date); // Thursday Feb 2015
      const dow = dateNow.day();
      switch (dow) {
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
        case 7:
          return "Sunday";
        default:
          return "";
      }
    },
    convertTime(start, end) {
      let format = "DD MMMM YYYY hh:mm a";
      const startNow = moment(start).format(format);
      const endNow = moment(end).format(format);
      if (startNow == endNow) {
        format = "hh:mm a";
        return moment(end).format(format);
      } else {
        const dayOfWeek = this.getDaysOfWeek(end);
        format = "DD MMMM YYYY hh:mm a";
        return dayOfWeek + " " + moment(end).format(format);
      }
    },
    convertDate(date) {
      const dayOfWeek = this.getDaysOfWeek(date);
      const format = "DD MMMM YYYY hh:mm a";
      return dayOfWeek + " " + moment(date).format(format);
    },
    enrollCourse(course, id) {
      this.$router.push("/enroll-course/" + course + "/" + id);
    },
    getVenue(id) {
      const details = this.venues.results.filter((item) => item.id == id);
      return details ? details[0].name : null;
    },
  },
  created() {
    // this.getAllEvents()
    this.$store.dispatch("getAllParticipants");
  },
  computed: {
    ...mapState({
      course_id_request: (state) => state.course_id_request,
      event_id_request: (state) => state.event_id_request,
      participant_id: (state) => state.participant_id,
      enrolment_id: (state) => state.enrolment_id,
      itemsParticipants: (state) => state.itemsParticipants,
    }),
    getParticipantFullname() {
      let fullName = this.itemsParticipants.filter(
        (participant) => participant.id == this.participant_id
      );
      if (fullName.length > 0) {
        return (
          fullName[0].title +
          " " +
          fullName[0].first_name +
          " " +
          fullName[0].last_name
        );
      }

      return "";
    },
    courseDetails() {
      const id = this.getCourseDetails[0].id;
      // alert('this.getCourseDetails[0].id'+this.getCourseDetails[0].id)
      const details = this.events.filter((item) => item.course == id);
      return details;
    },
    // getCourseDetails() {
    //   const details = this.items.filter(
    //     (item) => item.id == this.$route.params.id
    //   );
    //   return details;
    // },
  },
  components: {
    VenueStreetAddress,
    NumberDaysOfEvents,
    TotalEnrolled,
    EnrollNow,
  },
};
</script>
<style scoped>
p>img{
    width: 20vw;
  }
body {
  background: #dddcdc;
}
.btn-span {
  padding-bottom: 5px;
}
.btn-span a {
  background: rgb(255, 175, 75);
}
body {
  background-color: #f2f2f2;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 5px;
  color: #535353;
}
.course a {
  color: #f60;
}
.alert-message {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 18px;
  color: #404040;
  background-color: #eedc94;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  border-width: 1px;
  border-style: solid;
}
.row {
  display: flex;
  justify-content: center;
}
.col1 {
  background-color: #f1f1f3;
  border-right: 1px dotted #f60;
  width: 350px;
}
.col2 {
  width: 460px;
  margin-left: 30px;
}

.main {
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 850px;
  background-color: #fff;
  box-shadow: 10px 10px 10px #ccc;
}
.header-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-logo a {
  color: #0088cc;
  text-decoration: none;
  cursor: pointer;
}
.main-content {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-content h1 {
  font-size: 30px;
  line-height: 36px;
  padding-top: 10px;
  padding-left: 5px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #f60;
}
h1,
h2,
h3 {
  font-weight: bold;
}
</style>
