<template>
  <span>
    <span v-if="charAt == '<'">
      <span v-html="street_address"></span>
    </span>
    <span v-else>
      {{ street_address }}
    </span>
  </span>
</template>
<script>
export default {
  props: ["charAt", "street_address"],
};
</script>