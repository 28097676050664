<template>
  <div class="main-enroll-course">
    <div class="main">
      <div class="header-logo">
        <a href="https://www.duxtel.com.au/index.html">
          <img src="https://www.duxtel.com.au/images/duxtel_logo.gif" alt="" />
        </a>
      </div>
      <div v-if="getCourseDetails" class="main-content">
        <!-- {{ getCourseDetails.name.toUpperCase() }} -->
        <h1>
          Payment for enrolment in
          {{ getCourseDetails ? getCourseDetails.name.toUpperCase() : "" }}
        </h1>
        <div class="row">
          <div class="col1">
            <div class="row">
              <div style="width: 20%">
                <label for="">Details</label>
              </div>

              <div style="width: 70%" class="details">
                <div
                  v-if="getCourseDetails"
                  v-html="getCourseDetails.details"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Cost</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  ${{ getEventDetails ? getEventDetails.cost : "" }}
                  <!-- {{ getCourseDetails.details }} -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Venue</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  {{ getVenueDetails ? getVenueDetails.name : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Address</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ getVenueDetails ? getVenueDetails.street_address : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">City</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ getVenueDetails ? getVenueDetails.city : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Max. Participants </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ getEventDetails ? getEventDetails.max_participants : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Confirmed Participants </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    getEventDetails ? getEventDetails.confirmed_enrolment_count : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Interested </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  {{ getEventDetails ? getEventDetails.enrolment_count : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Seats Left </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    getEventDetails
                      ? getEventDetails.max_participants -
                       getEventDetails.enrolment_count
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">When </label>
              </div>
              <div style="width: 70%">
                <!-- v-if="getEventDetails" -->
                <DatesList
                  v-if="getEventDetails"
                  :getEventDetails="getEventDetails"
                />
              </div>
            </div>
          </div>
        </div>
        <h1>Participant Details</h1>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for=""> </label>
              </div>
              <div style="width: 70%">
                <!-- <div id="captcha"></div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <!-- <label for="">Type the letters/numbers above here. </label> -->
              </div>
              <div style="width: 70%; display: flex; justify-content: flex-end">
                <img
                  :src="
                    'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=https://training.duxtel.com.au/signup/payment/' +
                    enrollment.uuid
                  "
                />
                <!-- v-model="matchCaptchaCode" -->
                <!-- <input
                type="text"
                v-model.trim="$v.matchCaptchaCode.$model"
                :class="[
                  'details',
                  { 'form-error': $v.matchCaptchaCode.$error },
                ]"
              />
              <div class="error" v-if="!$v.matchCaptchaCode.required">
                Captcha is required
              </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
        <div class="col1">
          <div class="row mt-20">
            <div style="width: 20%">
              <label for="">Email Address </label>
            </div>
            <div style="width: 70%">
              <input
                type="text"
                v-model.trim="$v.email.$model"
                :class="['details', { 'form-error': $v.email.$error }]"
              />
              <div class="error" v-if="!$v.email.required">
                Email is required
              </div>
            </div>
          </div>
        </div>
      </div> -->
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Company Name </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    itemParticipantsDetails.companyName
                      ? itemParticipantsDetails.companyName
                      : itemParticipantsDetails.company_name
                      ? itemParticipantsDetails.company_name
                      : "&nbsp;"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Title </label>
              </div>
              <div style="width: 70%">
                <select
                  disabled
                  v-model="itemParticipantsDetails.title"
                  class="details"
                  name=""
                  id=""
                >
                  <option
                    :selected="
                      itemParticipantsDetails.title == 'Mr' ? true : false
                    "
                    value="Mr"
                  >
                    Mr
                  </option>
                  <option
                    :selected="
                      itemParticipantsDetails.title == 'Ms' ? true : false
                    "
                    value="Ms"
                  >
                    Ms
                  </option>
                  <option
                    :selected="
                      itemParticipantsDetails.title == 'Mrs' ? true : false
                    "
                    value="Mrs"
                  >
                    Mrs
                  </option>
                  <option
                    :selected="
                      itemParticipantsDetails.title == 'Mrs' ? true : false
                    "
                    value="Mrs"
                  >
                    Miss
                  </option>
                </select>
                <!-- <input type="text" class="details" /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">First Name </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    itemParticipantsDetails.firstName ||
                    itemParticipantsDetails.first_name
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Last Name </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    itemParticipantsDetails.lastName ||
                    itemParticipantsDetails.last_name
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Phone Number </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    itemParticipantsDetails.phoneNumber ||
                    itemParticipantsDetails.phone_number
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Postal Address </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    itemParticipantsDetails.postalAddress ||
                    itemParticipantsDetails.address_line_1
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Address Line 2 </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    itemParticipantsDetails.address_line_2
                      ? itemParticipantsDetails.address_line_2
                      : "&nbsp;"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Suburb </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ itemParticipantsDetails.suburb }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">State </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ itemParticipantsDetails.state }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Post Code </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  {{
                    itemParticipantsDetails.postCode ||
                    itemParticipantsDetails.post_code
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Country </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ itemParticipantsDetails.country }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <h1>Payment Details</h1>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for=""> &nbsp;</label>
              </div>
              <div style="width: 70%">
                <!-- <div id="captcha"></div> -->
                <p style="color: green; font-weight: bold">
                  Open for full registration!
                </p>
                <p>
                  Making multiple enrollments? Ask us about our volume discount
                  scheme!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="checkIfAllowedEnrolled" id="check-if-available">
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> Course Cost</label>
                </div>
                <div style="width: 70%">
                  <div class="details">
                    {{
                      getCourseDetails
                        ? getCourseDetails.name.toUpperCase()
                        : ""
                    }}
                  </div>
                  <div>${{ getEventDetails.cost.toFixed(2) }}</div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> Meal Options</label>
                </div>
                <div v-if="getEventDetails" style="width: 70%;padding: 20px;">
                  <div
                    v-for="(meal, index) in getEventDetails.meal_options"
                    :key="index"
                  >
                    <div class="row d-flex justify-content-between">
                      <label for="">
                        <input
                          type="radio"
                          @change="checkMealSelected($event, meal)"
                          name="meal_options"
                          :disabled="payment_successful || enrollment.paid"
                        /> <span class="px-3">{{ meal.description }}</span>
                      </label>
                      <div>${{ meal.cost.toFixed(2) }}</div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> Extras</label>
                </div>
                <div style="width: 70%;padding: 20px;">
                  <!-- {{enrollment.extras}} -->
                  <div
                    v-for="(extra, index) in getCourseDetails.extra_options"
                    :key="index"
                  >
                    <div class="row d-flex justify-content-between">
                      <label
                        ><input
                          type="checkbox"
                          @change="checkExtraSelected($event, extra)"
                          :disabled="payment_successful || enrollment.paid"
                        /><span class="px-3">{{ extra.description }}</span></label
                      >
                      <div>${{ extra.cost.toFixed(2) }}</div>
                    </div>

                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> Total</label>
                </div>
                <div style="width: 70%">
                  <div class="row">
                    <div>${{ calculateTotalCost }}</div>
                  </div>

                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> Name</label>
                </div>
                <!-- check if the participant firstname is not null then use the participant -->
                <div v-if="participant.first_name" style="width: 70%">
                  <div class="details">
                    {{ participant.first_name }} {{ participant.last_name }}
                  </div>
                </div>
                
                <div v-else style="width: 70%">
                  <!-- checkif the  itemParticipantsDetails is not empty then use it.-->
                  <div v-if="itemParticipantsDetails" class="details">
                    {{ itemParticipantsDetails.first_name }} {{ itemParticipantsDetails.last_name }}
                  </div>
                </div>
               
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> Email</label>
                </div>
                <div style="width: 70%">
                  <div  v-if="participant.email_address"  class="details">{{ participant.email_address }}</div>
                  <div  v-else  class="details">
                    <!-- checkif the  itemParticipantsDetails is not empty then use it.-->
                    <template v-if="itemParticipantsDetails">{{itemParticipantsDetails.email_address}}</template>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="!enrollment.paid">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Card Number</label>
                </div>
                <div style="width: 70%">
                  <input type="text" v-model="cardNumber" class="details" />
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="!enrollment.paid">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Expiry Date</label>
                </div>
                <div style="width: 70%">
                  <input type="text" v-model="expiryDate" class="details" />
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="!enrollment.paid">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">CVV</label>
                </div>
                <div style="width: 70%">
                  <input type="text" v-model="cvv" class="details" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""></label>
                </div>
                <div style="width: 70%">
                  <b-alert
                    variant="success"
                    show
                    v-if="payment_successful || enrollment.paid"
                    >Payment successful</b-alert
                  >
                  <b-alert variant="danger" show v-if="payment_failed"
                    >Payment failed</b-alert
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> </label>
                </div>
                <div style="width: 70%">
                  <span
                    class="btn-span"
                    style="float: left; padding-bottom: 5px"
                  >
                    <!-- <router-link class="btn duxtel" :to="'/course/enroll_course/'">
                  Enrol Now
                </router-link> -->

                    <a @click="pay(getEventDetails)" class="btn duxtel" v-if="!enrollment.paid"
                      >Pay</a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <template>
            <div v-if="checkIfNotYetTimeToEnroll">
              <div class="row">
                <div class="col1">
                  <div class="row mt-20">
                    <div style="width: 20%">
                      <label for=""> </label>
                    </div>
                    <div style="width: 70%">
                      <div class="alert-meessage">
                        Not yet accepting payments until 
                        {{ getStartDate }}
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col1">
                  <div class="row mt-20">
                    <div style="width: 20%">
                      <label for=""> </label>
                    </div>
                    <div style="width: 70%">
                      <div class="alert-meessage">
                        Payment no longer accepted since
                        {{ getLastDate }}
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import DatesList from "@/components/DatesList.vue";

export default {
  components: { DatesList },

  data() {
    return {
      code: "",
      showItemEvents: false,
      showItemVenues: false,

      matchCaptchaCode: "",
      email: "",
      companyName: "",
      title: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      postalAddress: "",
      addressLine2: "",
      suburb: "",
      state: "",
      postCode: "",
      country: "",
      extra_cost: 0,
      meal_options_cost: 0,

      cardNumber: "",
      expiryDate: "",
      cvv: "",

      payment_successful: false,
      payment_failed: false,
    };
  },

  methods: {
    ...mapActions({}),

    checkExtraSelected(event, extra) {
      if (event.target.checked) {
        this.extra_cost = this.extra_cost + extra.cost;
      } else {
        this.extra_cost = this.extra_cost - extra.cost;
      }
    },
    checkMealSelected(event, meal) {
      if (event.target.checked) {
        this.meal_options_cost = meal.cost;
      }
    },
    validateCaptcha() {
      // console.log('this.$v.$invalid',this.$v)
      // this.$v.$touch();
      // if (this.$v.$anyError || this.matchCaptchaCode != this.code) {
      //   return;
      // }
      // alert("redirect now");
      // if (this.item.matchCaptchaCode == this.code) {
      //   // alert("Valid Captcha");
      // } else {
      //   alert("Invalid Captcha. try Again");
      //   this.createCaptcha();
      // }
    },
    createCaptcha() {
      //clear the contents of captcha div first
      // var checkDivCaptcha = (document.getElementById("captcha").innerHTML = "");
      if (document.getElementById("captcha")) {
        document.getElementById("captcha").innerHTML = "";
        var charsArray =
          "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lengthOtp = 8;
        var captcha = [];
        for (var i = 0; i < lengthOtp; i++) {
          //below code will not allow Repetition of Characters
          var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
          if (captcha.indexOf(charsArray[index]) == -1)
            captcha.push(charsArray[index]);
          else i--;
        }
        var canv = document.createElement("canvas");
        canv.id = "captcha";
        canv.width = 200;
        canv.height = 50;
        var ctx = canv.getContext("2d");
        ctx.font = "25px Georgia";
        ctx.strokeText(captcha.join(""), 0, 30);
        //storing captcha so that can validate you can save it somewhere else according to your specific requirements
        this.code = captcha.join("");
        document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
      }
    },
    async pay(getEventDetails) {
      // Validate captcha
      // this.validateCaptcha();

      console.log('getEventDetails',getEventDetails);
      let data = {
        cardNumber: this.cardNumber,
        expiryDate: this.expiryDate,
        cvv: this.cvv,
        amount: (parseFloat(this.calculateTotalCost) * 100).toString(),
        currency: "AUD",
        purchaseOrderNo: this.enrollment.id.toString(),
      };

      axios
        .request({
          url: `${process.env.VUE_APP_BASE_URL}secure_pay/pay`,
          method: "post",
          data: data,
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_SECRET_TOKEN}`,
            "Content-Type": "application/json",
          },
        })
        .then(async(response) => {
          if (response.status === 200 && response.data.responseCode === "00") {
            this.payment_successful = true;
            this.payment_failed = false;
            this.enrollment.paid = true;

            // update event details 
            // call an api for updating the event here
            const data = {...getEventDetails, eventType: 'INCREMENT_CONFIRMED_ENROLLMENTS' }
            await this.$store.dispatch("updateEvent", data);
          } else {
            this.payment_failed = true;
            this.payment_successful = false;
            this.enrollment.paid = false;
          }
        })
        .catch(() => {
          this.payment_failed = true;
          this.payment_successful = false;
          this.enrollment.paid = false;
        });
    },
  },

  computed: {
    ...mapGetters(["getCourseDetails", "getEventDetails", "getVenueDetails"]),
    ...mapState({
      course_id: (state) => state.course_id,
      event_id: (state) => state.event_id,
      itemParticipantsDetails: (state) => state.itemParticipantsDetails,
      participant: (state) => state.participant,
      enrollment: (state) => state.enrollment,
      itemsCourse: (state) => state.itemsCourse,
    }),
    getStartDate() {
      if (this.getEventDetails.payment_start_date) {
        var payment_start_date = moment(
          this.getEventDetails.payment_start_date
        ).format("DD/MM/YYYY");
        return payment_start_date;
      }
      return null;
    },
    getLastDate() {
      if (this.getEventDetails.payment_last_date) {
        var payment_last_date = moment(
          this.getEventDetails.payment_last_date
        ).format("DD/MM/YYYY");
        return payment_last_date;
      }
      return null;
    },
    checkIfAllowedEnrolled() {
      var payment_start_date = moment(
        this.getEventDetails.payment_start_date
      ).format("YYYY-MM-DD");
      var payment_last_date = moment(
        this.getEventDetails.payment_last_date
      ).format("YYYY-MM-DD");
      var current_date = moment().format("YYYY-MM-DD");
      if (
        current_date >= payment_start_date &&
        current_date <= payment_last_date &&
        this.getEventDetails.payment_last_date &&
        this.getEventDetails.payment_start_date
      ) {
        return true;
      }
      return false;
    },
    checkIfNotYetTimeToEnroll() {
      var payment_start_date = moment(
        this.getEventDetails.payment_start_date
      ).format("YYYY-MM-DD");

      var current_date = moment().format("YYYY-MM-DD");
      if (
        current_date <= payment_start_date &&
        this.getEventDetails.payment_last_date &&
        this.getEventDetails.payment_start_date
      ) {
        return true;
      }
      return false;
    },
    calculateTotalCost() {
      let total =
        this.getEventDetails.cost + this.extra_cost + this.meal_options_cost;

      return total.toFixed(2);
    },
  },
  async created() {
    await this.$store.dispatch("getAllCourse");
    await this.$store.dispatch("getAllEvents");
    await this.$store.dispatch("getAllVenues");
    await this.$store.dispatch("getEnrolment", this.$route.params.uuid);
    // await this.$store.dispatch("setCourseId", this.course_id);
    // await this.$store.dispatch("setEventId", this.event_id);
    setTimeout(() => this.createCaptcha(), 1000);
  },
};
</script>
<style scoped>
.alert-meessage {
  width: 460px;
  padding: 8px 35px 8px 14px;
  margin-bottom: 18px;
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 4px;
  color: #c09853;
}
.main-enroll-course {
  margin: 0 !important;
  background: #f5f5f5 !important;
}
#app {
  margin: 0 !important;
  background: #f5f5f5 !important;
}
.form-error {
  border: 1px solid red !important;
}
.error {
  color: red;
  font-size: 12px;
}
body {
  background-color: #f9f8f8 !important;
}
.mt-20 {
  margin-top: 20px;
}
.notAllowed {
  cursor: not-allowed;
}
.details {
  width: 210px;
  height: 18px;
  padding: 4px;
  /* width: 590px; */
  height: auto;
  color: #535353;
  padding: 5px;
  border: 1px solid #cccccc;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 3%);

  border-radius: 3px;
  /* background-color: #f1f1f3; */
}
.btn-span {
  padding-bottom: 5px;
}
.btn-span a {
  background: rgb(255, 175, 75);
}
body {
  background-color: #f2f2f2;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 5px;
  color: #535353;
}
.course a {
  color: #f60;
}
.alert-message {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 18px;
  color: #404040;
  background-color: #eedc94;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  border-width: 1px;
  border-style: solid;
}
.row {
  display: flex;
  justify-content: space-around;
}
.col1 {
  /* background-color: #f1f1f3; */
  /* border-right: 1px dotted #f60; */
  width: 90%;
  /* height: 100px; */
  margin: auto;
}
.col2 {
  width: 460px;
  margin-left: 30px;
}

.main {
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 850px;
  background-color: #fff;
  box-shadow: 10px 10px 10px #ccc;
}
.header-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-logo a {
  color: #0088cc;
  text-decoration: none;
  cursor: pointer;
}
.main-content {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-content h1 {
  font-size: 30px;
  line-height: 36px;
  padding-top: 10px;
  padding-left: 5px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #f60;
}
h1,
h2 {
  font-weight: bold;
}
canvas {
  /*prevent interaction with the canvas*/
  pointer-events: none;
}
canvas {
  border: 1px solid;
}
</style>
