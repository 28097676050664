<template >
  <div class="details notAllowed" style="width: 100%">
    <div :key="index" v-for="(date, index) in getEventDetails.dates">
      <!-- {{ getCourseDetails.details }} -->
      <b>{{ convertDate(date.start_date) }}&nbsp;-&nbsp;{{ convertDate(date.end_date) }}</b>
      <br />
      <label>{{ date.description }}</label>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["getEventDetails"],
  methods: {
    convertDate(date, dateOnly = false) {
      let format = "DD/MM/YYYY HH:mm";
      if (dateOnly) {
        format = "HH:mm A";
      } else {
        format = "DD/MM/YYYY HH:mm";
      }
      return moment(date).format(format);
    },
  },
};
</script>