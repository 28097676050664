<template>
  <div>{{ getNumberOfDates }} {{getNumberOfDates > 1? 'Days':'Day'}}:</div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    dates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getNumberOfDates() {
      if (this.dates.length) {
        let comDate = "";
        let dateLength = 0;
        this.dates.map((date) => {
          const newDate = moment(date.start_date).format("DD/MM/YYYY");
          if (newDate != comDate) {
            dateLength = dateLength + 1;
            comDate = newDate;
          }
        });

        return parseInt(dateLength);
      }

      return null;
    },
  },
};
</script>