<template>
  <div class="main">
    <div class="header-logo">
      <a href="https://www.duxtel.com.au/index.html">
        <img src="https://www.duxtel.com.au/images/duxtel_logo.gif" alt="" />
      </a>
    </div>
    <div class="main-content">
      <h1>Select Course</h1>
      <div class="row">
        <!-- href="https://training.duxtel.com.au/course/?id=1&q=&enrolment.id=" -->
        <!-- <a href="#" @click="getCourseDetails(item.id)">{{
                  item.name.toUpperCase()
                }}</a> -->
        <div v-if="showItems && showItemVenues && showItemEvents" class="col1">
          <template v-for="(item, index) in items">
            <div class="course" :key="index">
              <h2>
                <router-link :to="'/selected-course/' + item.id">{{
                  item.name.toUpperCase()
                }}</router-link>
              </h2>
              <div class="coursedetails">
                {{ item.subline }}
              </div>
              <events-per-course :events="itemsEvents" :course_id="item.id" />
            </div>
          </template>
          <!-- <div style="margin-top: 20px">
            <a
              style="font-size: 14px"
              :href="VUE_APP_BASE_URL_APP_ADMIN + '/static'"
              >admin</a
            >
          </div> -->
        </div>
        <div class="col2">
          <template v-if="$route.params.id && getCourseDetails">
            <course-details :getCourseDetails="getCourseDetails" />
          </template>
          <template
            v-if="
              $route.params.id &&
              getCourseDetails &&
              showItemVenues &&
              showItemEvents
            "
          >
            <course-events
              :getCourseDetails="getCourseDetails"
              :events="itemsEvents"
              :venues="itemsVenues"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseDetails from "../components/CourseDetails";
import CourseEvents from "../components/CourseEvents";
import EventsPerCourse from "@/components/EventsPerCourse.vue";
export default {
  data() {
    return {
      VUE_APP_SECRET_TOKEN: process.env.VUE_APP_SECRET_TOKEN,
      VUE_APP_BASE_URL: process.env.VUE_APP_BASE_URL,
      VUE_APP_BASE_URL_APP_ADMIN: process.env.VUE_APP_BASE_URL_APP_ADMIN,
      items: [],
      itemsEvents: [],
      itemsVenues: [],
      showItems: false,
      showItemEvents: false,
      showItemVenues: false,
    };
  },
  components: {
    CourseDetails,
    CourseEvents,
    EventsPerCourse,
  },
  methods: {
    async getAllVenues() {
      this.axios
        .create({
          baseURL: this.VUE_APP_BASE_URL,
          headers: {
            "Content-Type": "*/*",
            Authorization: "bearer " + this.VUE_APP_SECRET_TOKEN,
          },
        })
        .get("/api/venue/?_code=true&limit=2000&offset=0&q=")
        .then((res) => {
          console.log("api/venues", res);
          this.itemsVenues = res.data;
          this.showItemVenues = true;
        });
    },
    async getAllEvents() {
      this.axios
        .create({
          baseURL: this.VUE_APP_BASE_URL,
          headers: {
            "Content-Type": "*/*",
            Authorization: "bearer " + this.VUE_APP_SECRET_TOKEN,
          },
        })
        .get("/api/event/?filter=active")
        .then((res) => {
          console.log("api/events", res);
          this.itemsEvents = res.data;
          this.showItemEvents = true;
        });
    },
    async getAllCourse() {
      this.axios
        .create({
          baseURL: this.VUE_APP_BASE_URL,
          headers: {
            "Content-Type": "*/*",
            Authorization: "bearer " + this.VUE_APP_SECRET_TOKEN,
          },
        })
        .get("/api/course/?filter=active")
        .then((res) => {
          // console.log("api/course", res);
          this.items = res.data;
          this.showItems = true;
        });
    },
  },
  computed: {
    getCourseDetails() {
      const details = this.items.filter(
        (item) => item.id == this.$route.params.id
      );
      return details;
    },
  },
  created() {
    // console.log('token',process.env.SECRET_TOKEN);
    this.getAllCourse();
    this.getAllEvents();
    this.getAllVenues();
  },
};
</script>
<style scoped>
.btn-span {
  padding-bottom: 5px;
}
.btn-span a {
  background: rgb(255, 175, 75);
}
body {
  background-color: #f2f2f2;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 5px;
  color: #535353;
}
.course a {
  color: #f60;
}
.alert-message {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 18px;
  color: #404040;
  background-color: #eedc94;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  border-width: 1px;
  border-style: solid;
}
.row {
  display: flex;
  justify-content: center;
}
.col1 {
  background-color: #f1f1f3;
  border-right: 1px dotted #f60;
  width: 350px;
}
.col2 {
  width: 460px;
  margin-left: 30px;
}

.main {
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 850px;
  background-color: #fff;
  box-shadow: 10px 10px 10px #ccc;
}
.header-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-logo a {
  color: #0088cc;
  text-decoration: none;
  cursor: pointer;
}
.main-content {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-content h1 {
  font-size: 30px;
  line-height: 36px;
  padding-top: 10px;
  padding-left: 5px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #f60;
  text-align: start;
}
h1,
h2 {
  font-weight: bold;
}
</style>