<template>
  <p v-if="max_participants" class="">
    <font size="2" color="#1f497d" face="Calibri"
      ><span style="font-size: 11pt; color: #1f497d"
        >{{ getRemainingSlots }}
      </span>
    </font>
  </p>
</template>

<script>
export default {
  props: ["max_participants", "confirmed_enrolments", "enrolments"],
  computed: {
    getRemainingSlots() {
      const total = this.max_participants - this.enrolments;
      return total;
    },
  },
};
</script>
