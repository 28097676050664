<template>
  <b-button
    :style="getRemainingSlots <= 0?'cursor: not-allowed':''" 
    :disabled="getRemainingSlots <= 0? true: false"
    v-if="!event_id_request"
    @click="enrollCourse()"
    :variant="getRemainingSlots <= 0? 'primary': 'warning'"
  >
  {{ getRemainingSlots <= 0? 'Fully Booked': 'Enroll Now' }}
    
  </b-button>
</template>

<script>
export default {
  props: [
    "event_id_request",
    "course",
    "id",
    "max_participants",
    "confirmed_enrolments",
    "enrolments",
  ],
  methods: {
    enrollCourse() {
      this.$emit("enrollCourse", this.course, this.id);
    },
  },
  computed: {
    getRemainingSlots() {
      const total = this.max_participants - this.enrolments;
      return total;
    },
  },
};
</script>
