<template>
  <div>
    <!-- {{courseDetails}} -->
    <ul v-if="events">
      <li v-for="(detail, index) in courseDetails" :key="index">
        <template v-if="detail && detail.dates.length>0">
          {{ convertDate(detail.dates[0].start_date) }} -
          {{ convertDate(detail.dates[detail.dates.length - 1].end_date) }}
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["events", "course_id"],
  methods: {
    convertDate(date) {
      //   let format = "DD/MM/YYYY hh:mm";
      let format = "DD MMMM YYYY";
      return moment(date).format(format);
    },
  },
  computed: {
    courseDetails() {
      const id = this.course_id;
      //   alert("this.getCourseDetails[0].id" + this.getCourseDetails[0].id);
      const details = this.events.filter((item) => item.course == id);
      return details;
    },
  },
};
</script>
