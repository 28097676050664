<template>
  <div></div>
</template>
<script>
export default {
  created(){
    console.log('this.$router',this.$router)
    // store the course id = id
    this.$store.dispatch('setCourseIdRequest', this.$route.params.id)
    // store the event id = event_id
    this.$store.dispatch('setEventIdRequest', this.$route.params.event_id)
    // store the participant id = participant_id
    this.$store.dispatch('setParticipantId', this.$route.params.participant_id)
    // store the enrolment id = enrolment_id
    this.$store.dispatch('setEnrolmentId', this.$route.params.enrolment_id)
    this.$router.push('/selected-course/'+this.$route.params.id)
  }
};
</script>