<template>
  <div class="main-enroll-course">
    <div class="main">
      <div class="header-logo">
        <a href="https://www.duxtel.com.au/index.html">
          <img src="https://www.duxtel.com.au/images/duxtel_logo.gif" alt="" />
        </a>
      </div>
      <div class="main-content">
        <!-- {{ getCourseDetails.name.toUpperCase() }} -->
        <h1>
          Enrol in
          {{ getCourseDetails ? getCourseDetails.name.toUpperCase() : "" }}
        </h1>
        <div class="row">
          <div class="col1">
            <div class="row">
              <div style="width: 20%">
                <label for="">Details</label>
              </div>

              <div style="width: 70%" class="details">
                <div
                  v-if="getCourseDetails"
                  v-html="getCourseDetails.details"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Cost</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  ${{ getEventDetails ? getEventDetails.cost : "" }}
                  <!-- {{ getCourseDetails.details }} -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Venue</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  {{ getVenueDetails ? getVenueDetails.name : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Address</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ getVenueDetails ? getVenueDetails.street_address : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">City</label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ getVenueDetails ? getVenueDetails.city : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Max. Participants </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ getEventDetails ? getEventDetails.max_participants : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Confirmed Participants </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    getEventDetails
                      ? getEventDetails.confirmed_enrolment_count
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Interested </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{ getEventDetails ? getEventDetails.enrolment_count : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Seats Left </label>
              </div>
              <div style="width: 70%">
                <div class="details notAllowed">
                  <!-- {{ getCourseDetails.details }} -->
                  {{
                    getEventDetails
                      ? getEventDetails.max_participants -
                        getEventDetails.enrolment_count
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">When </label>
              </div>
              <div style="width: 70%">
                <DatesList
                  v-if="getEventDetails"
                  :getEventDetails="getEventDetails"
                />
              </div>
            </div>
          </div>
        </div>
        <h1>Participant Details</h1>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for=""> </label>
              </div>
              <div style="width: 70%">
                <div id="captcha"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Type the letters/numbers above here. </label>
              </div>
              <div style="width: 70%">
                <!-- v-model="matchCaptchaCode" -->
                <input
                  type="text"
                  v-model.trim="$v.matchCaptchaCode.$model"
                  :class="[
                    'details',
                    { 'form-error': $v.matchCaptchaCode.$error },
                  ]"
                />
                <!-- $v.matchCaptchaCode.required -->
                <div class="error" v-if="$v.matchCaptchaCode.$error">
                  Captcha is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col1">
            <div class="row mt-20">
              <div style="width: 20%">
                <label for="">Email Address </label>
              </div>
              <div style="width: 70%">
                <!-- <input type="text" class="details" /> -->
                <b-input-group style="width: 46%">
                  <b-form-input
                    v-model="$v.email.$model"
                    :class="['details', $v.email.$error ? 'form-error' : '']"
                    @blur="verifyParticipants"
                  ></b-form-input>
                  <!-- <b-input-group-append>
                    <b-button variant="outline-success" size="sm"
                      @click="verifyParticipants"
                      :disabled="!$v.email.email || !$v.email.required"
                      v-b-modal.modal-1>
                      Verify</b-button>
                  </b-input-group-append> -->
                </b-input-group>
                <div class="error" v-if="$v.email.$error">
                  {{ !$v.email.required ? "Email is required" : "" }} <br />
                  {{ !$v.email.email ? "Must be an email" : "" }}
                </div>

                <b-input-group style="width: 48.3%" v-if="emailKey">
                  <b-alert variant="info" show
                    >You have a previous enrollment! We've sent you an email.
                    Please copy the email key from your email to the email key
                    field below and we will load your details.</b-alert
                  >
                  <b-form-input
                    v-model="emailKeys"
                    class="details"
                    placeholder="Email Key"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="verifyEmailKeys"
                      :disabled="!$v.email.email || !$v.email.required"
                      v-b-modal.modal-1
                    >
                      Confirm</b-button
                    >
                  </b-input-group-append>
                </b-input-group>

                <div v-if="error" style="width: 48.3%">
                  <b-alert variant="warning" show>{{ error }}</b-alert>
                </div>

                <!-- <input
                  type="email"
                  v-model.trim="$v.email.$model"
                  :class="['details', $v.email.$error? 'form-error':'']"
                />
                <div class="error" v-if="$v.email.$error">
                  {{ !$v.email.required ? "Email is required" : "" }} <br />
                  {{ !$v.email.email ? "Must be an email" : "" }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Company Name </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.companyName.$model"
                    :class="[
                      'details',
                      $v.companyName.$error ? 'form-error' : '',
                    ]"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.companyName.$error">
                    Company name is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Title </label>
                </div>
                <div style="width: 70%">
                  <select
                    :class="['details', $v.title.$error ? 'form-error' : '']"
                    v-model.trim="$v.title.$model"
                    :disabled="!checked_participant"
                  >
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Mrs">Miss</option>
                  </select>
                  <div class="error" v-if="$v.title.$error">
                    Title is required
                  </div>
                  <!-- <input type="text" class="details" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">First Name </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.firstName.$model"
                    :class="[
                      'details',
                      $v.firstName.$error ? 'form-error' : '',
                    ]"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.firstName.$error">
                    First name is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Last Name </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.lastName.$model"
                    :class="['details', $v.lastName.$error ? 'form-error' : '']"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.lastName.$error">
                    Last name is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Phone Number </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.phoneNumber.$model"
                    :class="[
                      'details',
                      $v.phoneNumber.$error ? 'form-error' : '',
                    ]"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.phoneNumber.$error">
                    Phone number is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Postal Address </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.postalAddress.$model"
                    :class="[
                      'details',
                      $v.postalAddress.$error ? 'form-error' : '',
                    ]"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.postalAddress.$error">
                    Postal address is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Address Line 2 </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    class="details"
                    v-model.trim="addressLine2"
                    :disabled="!checked_participant"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Suburb </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.suburb.$model"
                    :class="['details', $v.suburb.$error ? 'form-error' : '']"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.suburb.$error">
                    Suburb is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">State </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.state.$model"
                    :class="['details', $v.state.$error ? 'form-error' : '']"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.state.$error">
                    State is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Post Code </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.postCode.$model"
                    :class="['details', $v.postCode.$error ? 'form-error' : '']"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.postCode.$error">
                    Post code is required
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for="">Country </label>
                </div>
                <div style="width: 70%">
                  <!-- <input type="text" class="details" /> -->
                  <input
                    type="text"
                    v-model.trim="$v.country.$model"
                    :class="['details', $v.country.$error ? 'form-error' : '']"
                    :disabled="!checked_participant"
                  />
                  <div class="error" v-if="$v.country.$error">
                    Country code is required
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col1">
              <div class="row mt-20">
                <div style="width: 20%">
                  <label for=""> </label>
                </div>
                <div v-if="computeSeatsLeft" style="width: 70%">
                  <span
                    class="btn-span"
                    style="float: left; padding-bottom: 5px"
                  >
                    <!-- <router-link class="btn duxtel" :to="'/course/enroll_course/'">
                    Enrol Now
                  </router-link> -->
                    <button
                      style="cursor: not-allowed"
                      disabled
                      class="btn duxtel btn-primary"
                    >
                      Fully Booked
                    </button>
                  </span>
                </div>
                <div v-else style="width: 70%" :disabled="!checked_participant">
                  <span
                    :class="
                      checked_participant
                        ? 'btn-span'
                        : !checked_participant
                        ? 'disable-link'
                        : ''
                    "
                    style="float: left; padding-bottom: 5px"
                  >
                    <!-- <router-link class="btn duxtel" :to="'/course/enroll_course/'">
                    Enrol Now
                  </router-link> -->

                    <a @click="validateCaptchaAndSubmit()" class="btn duxtel"
                      >Enroll Now</a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import DatesList from "@/components/DatesList.vue";

export default {
  components: { DatesList },

  data() {
    return {
      checked_participant: false /*Not checked yet*/,
      code: "",
      showItemEvents: false,
      showItemVenues: false,

      matchCaptchaCode: "",
      email: "",
      companyName: "",
      title: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      postalAddress: "",
      addressLine2: "",
      suburb: "",
      state: "",
      postCode: "",
      country: "",
      emailKeys: "",
      showEmailKey: false,
      emailKey: false,
      participantExists: false,
      participantDetail: {},
      id: "",
      error: "",
    };
  },
  validations: {
    matchCaptchaCode: {
      required,
    },
    email: {
      required,
      email,
    },
    companyName: {
      required,
    },
    title: {
      required,
    },
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    phoneNumber: {
      required,
    },
    postalAddress: {
      required,
    },
    suburb: {
      required,
    },
    state: {
      required,
    },
    postCode: {
      required,
    },
    country: {
      required,
    },
  },
  methods: {
    ...mapActions([
      "createParticipants",
      "verifyParticipant",
      "createEnrollment",
      "updateParticipant",
      "error",
    ]),
    errorMessage(variant = null, error) {
      // We only catch the error for Email address
      this.$bvToast.toast(
        error.email_address
          ? "Email address: " + error.email_address
          : error.non_field_errors
          ? error.non_field_errors
          : error,
        {
          title: `Error`,
          variant: variant,
          solid: true,
        }
      );
    },
    async validateCaptchaAndSubmit() {
      if (this.computeSeatsLeft) {
        alert("Fully booked!");
        return;
      }
      if (this.matchCaptchaCode != this.code) {
        alert("Captcha is incorrect, try again!");
        this.createCaptcha();
        return;
      }
      this.$v.$touch();
      //  console.log("this.$v.$invalid", this.$v);
      if (this.$v.$anyError) {
        return;
      }
      const items = {
        matchCaptchaCode: this.matchCaptchaCode,
        email: this.email,
        companyName: this.companyName,
        title: this.title,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        postalAddress: this.postalAddress,
        addressLine2: this.addressLine2,
        suburb: this.suburb,
        state: this.state,
        postCode: this.postCode,
        country: this.country,
        id: this.id,
      };
      console.warn("Items from Enroll now: ", items);

      const data = {
        ...items,
        eventType: "INCREMENT_ENROLLMENTS",
        getEventDetails: this.getEventDetails,
      };
      console.warn("Participant exists? ", this.participantExists);
      if (!this.participantExists) {
        await this.createParticipants(data)
          .then(() => {
            if (this.enrollment.id === undefined) {
              // do nothing
            } else {
              setTimeout(() => {
                console.log("enrollment", this.enrollment);
                this.$router.push("/dopayment/" + this.enrollment.id);
              }, 1000);
            }
          })
          .catch((err) => {
            console.warn("Error", err);
            this.errorMessage("danger", err.response);
          });
        await this.$store.dispatch("getAllItemParticipantsDetails", items);
      } else {
        await this.updateParticipant(data)
          .then(() => {})
          .catch((error) => {
            console.error("error", error);
          });
        await this.createEnrollment(data)
          .then((res) => {
            console.warn("Res in create enrollment: ", res);
            setTimeout(() => {
              console.log("enrollment", this.enrollment);
              this.$router.push("/dopayment/" + this.enrollment.id);
            }, 1000);
          })
          .catch((err) => {
            console.warn("Error", err);
          });
        await this.$store.dispatch(
          "getAllItemParticipantsDetails",
          this.participantDetail
        );
      }
    },
    createCaptcha() {
      if (document.getElementById("captcha")) {
        document.getElementById("captcha").innerHTML = "";
        var charsArray =
          "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var lengthOtp = 8;
        var captcha = [];
        for (var i = 0; i < lengthOtp; i++) {
          //below code will not allow Repetition of Characters
          var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
          if (captcha.indexOf(charsArray[index]) == -1)
            captcha.push(charsArray[index]);
          else i--;
        }
        var canv = document.createElement("canvas");
        canv.id = "captcha";
        canv.width = 200;
        canv.height = 50;
        var ctx = canv.getContext("2d");
        ctx.font = "25px Georgia";
        ctx.strokeText(captcha.join(""), 0, 30);
        //storing captcha so that can validate you can save it somewhere else according to your specific requirements
        this.code = captcha.join("");
        document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
      }
    },
    async verifyParticipant(payload) {
      Vue.axios
        .create({
          baseURL: process.env.VUE_APP_BASE_URL,
          headers: {
            "Content-Type": "*/*",
            Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
          },
        })
        .post("/api/verify_participant/", payload)
        .then(async (res) => {
          this.checked_participant = true;
          console.warn("Verify participant res:", res);
          this.emailKey = true;
          this.error = "";
        })
        .catch((error) => {
          this.checked_participant = true;
          this.emailKey = false;
          this.error = error.response.data.status;
        });
    },
    async verifyParticipants() {
      const vm = this;

      const payload = {
        email_address: vm.email,
      };
      this.checked_participant = false;
      console.warn("Payload: ", payload);

      await vm.verifyParticipant(payload);
    },
    async verifyEmailKeys() {
      const vm = this;
      const payload = {
        email_key: vm.emailKeys,
      };
      Vue.axios
        .create({
          baseURL: process.env.VUE_APP_BASE_URL,
          headers: {
            "Content-Type": "*/*",
            Authorization: "bearer " + process.env.VUE_APP_SECRET_TOKEN,
          },
        })
        .post("/api/verify_email_key/", payload)
        .then(async (res) => {
          vm.checked_participant = true;
          vm.participantExists = true;
          vm.participantDetail = res.data.user;
          vm.id = vm.participantDetail.id;
          vm.companyName = vm.participantDetail.company_name;
          vm.title = vm.participantDetail.title;
          vm.firstName = vm.participantDetail.first_name;
          vm.lastName = vm.participantDetail.last_name;
          vm.phoneNumber = vm.participantDetail.phone_number;
          vm.postalAddress = vm.participantDetail.address_line_1;
          vm.addressLine2 = vm.participantDetail.address_line_2;
          vm.suburb = vm.participantDetail.suburb;
          vm.state = vm.participantDetail.state;
          vm.postCode = vm.participantDetail.post_code;
          vm.country = vm.participantDetail.country;
        })
        .catch((error) => {
          vm.checked_participant = true;
          alert(error.response.data.user);
          vm.errorMessage("danger", error.response[0].status);
          console.log("error in verifying the Participant", error);
        });
    },
  },

  computed: {
    ...mapGetters([
      "getCourseDetails",
      "getEventDetails",
      "getVenueDetails",
      // "emailKey",
      "participantDetail",
      "error",
    ]),
    ...mapState({
      course_id: (state) => state.course_id,
      event_id: (state) => state.event_id,
      enrollment: (state) => state.enrollment,
    }),
    computeSeatsLeft() {
      if (this.getEventDetails) {
        if (
          this.getEventDetails.max_participants -
            this.getEventDetails.enrolment_count ==
          0
        ) {
          return true;
        }
      }

      return false;
    },
    needs_email_key() {
      console.info("Email key: ", this.emailKey);
      if (this.emailKey) {
        return this.checked_participant;
      }
      return false;
    },
  },
  async created() {
    await this.$store.dispatch("getAllCourse");
    await this.$store.dispatch("getAllEvents");

    await this.$store.dispatch("setCourseId", this.$route.params.id);
    await this.$store.dispatch("setEventId", this.$route.params.event_id);
    await this.$store.dispatch("getAllVenues");

    setTimeout(() => {
      this.createCaptcha();
      this.$store.dispatch("setVenueId", this.getEventDetails.venue);
      console.log("getEventDetails", this.getEventDetails);
    }, 1000);
  },
};
</script>
<style scoped>
.main-enroll-course {
  margin: 0 !important;
  background: #f5f5f5 !important;
}
.form-error {
  border: 1px solid red !important;
}
.error {
  color: red;
  font-size: 12px;
}

.mt-20 {
  margin-top: 20px;
}
.notAllowed {
  cursor: not-allowed;
}
.details {
  width: 210px;
  height: 18px;
  padding: 4px;
  /* width: 590px; */
  height: auto;
  color: #535353;
  padding: 5px;
  border: 1px solid #cccccc;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 3%);

  border-radius: 3px;
  /* background-color: #f1f1f3; */
}
.btn-span {
  padding-bottom: 5px;
}
.btn-span a {
  background: rgb(255, 175, 75);
}
body {
  background-color: #f2f2f2;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin: 5px;
  color: #535353;
}
.course a {
  color: #f60;
}
.alert-message {
  position: relative;
  padding: 7px 15px;
  margin-bottom: 18px;
  color: #404040;
  background-color: #eedc94;
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
  border-width: 1px;
  border-style: solid;
}
.row {
  display: flex;
  justify-content: space-around;
}
.col1 {
  /* background-color: #f1f1f3; */
  /* border-right: 1px dotted #f60; */
  width: 90%;
  /* height: 100px; */
  margin: auto;
}
.col2 {
  width: 460px;
  margin-left: 30px;
}

.main {
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  width: 850px;
  background-color: #fff;
  box-shadow: 10px 10px 10px #ccc;
}
.header-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-logo a {
  color: #0088cc;
  text-decoration: none;
  cursor: pointer;
}
.main-content {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-content h1 {
  font-size: 30px;
  line-height: 36px;
  padding-top: 10px;
  padding-left: 5px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #f60;
}
h1,
h2 {
  font-weight: bold;
}
canvas {
  /*prevent interaction with the canvas*/
  pointer-events: none;
}
canvas {
  border: 1px solid;
}
.disable-link {
  pointer-events: none;
}
</style>
